import { acceptHMRUpdate, defineStore } from 'pinia';
import { whenever } from '@vueuse/core';
import type {
  AddressResponse,
  CheckoutRequest,
  CheckoutStep,
  CreditCard,
  FflDealerResponse,
  FinancingApplication,
  OrderResponse,
  StoreCreditResponse,
  TransactionType,
  WarehouseResponse,
} from '~/types/ecommerce';
import {
  LazyCheckoutStepBillingAddress,
  LazyCheckoutStepFflDealer,
  LazyCheckoutStepPaymentOptions,
  // LazyCheckoutStepPickupLocation,
  LazyCheckoutStepSubmit,
  LazyCheckoutStepShipDestination,
  LazyCheckoutStepShippingAddress,
} from '#components';

interface Step {
  name: CheckoutStep;
  title: string;
  component: Component;
  caption?: MaybeRefOrGetter<string | undefined>;
  done?: MaybeRefOrGetter<boolean>;
}

export const useCheckoutStore = defineStore('checkout', () => {
  const quoteStore = useQuoteStore();
  const { $bus, $credova, $kount, $ecommerce } = useNuxtApp();
  const { address } = useFormatting();

  const drawer = ref(false);

  const billingAddress = ref<AddressResponse | null>(null);
  const shippingAddress = ref<AddressResponse | null>(null);
  const pickupLocation = ref<WarehouseResponse>({
    id: '101',
    name: 'KYGUNCO Bardstown',
    phoneNumber: '15023483594',
    company: 'KYGUNCO',
    street: '401 Glenwood Drive',
    street2: null,
    city: 'Bardstown',
    state: 'KY',
    postcode: '40004',
    country: 'US',
  });
  const fflDealer = ref<FflDealerResponse | null>(null);
  const paymentMethod = ref<TransactionType | null>(null);
  const storeCredits = ref<StoreCreditResponse[]>([]);
  const isPickup = ref(false);
  const isLayaway = ref(false);
  const isInsured = ref(true);
  const customerNotes = ref<string | null>(null);

  const creditCard = ref<CreditCard | null>(null);
  const financing = ref<FinancingApplication | null>(null);

  const request = computed(
    () =>
      ({
        sessionId: $kount.sessionId.value,
        billingAddressId: billingAddress.value?.id,
        shippingAddressId: shippingAddress.value?.id,
        pickupLocationId: pickupLocation.value?.id,
        fflDealerId: fflDealer.value?.id,
        isInsured: isInsured.value,
        isLayaway: isLayaway.value,
        isPickup: isPickup.value,
        paymentMethod: paymentMethod.value,
        customerNotes: customerNotes.value?.length
          ? customerNotes.value
          : undefined,
        creditCard:
          paymentMethod.value == 'CreditCard' && creditCard.value?.isValid
            ? {
                cardNumber: creditCard.value.number,
                securityCode: creditCard.value.code,
                expirationYear: creditCard.value.expYear,
                expirationMonth: creditCard.value.expMonth,
              }
            : undefined,
        financing:
          paymentMethod.value == 'Financing'
            ? { applicationId: financing.value?.id }
            : undefined,
        storeCredits: storeCredits.value.map(i => ({ code: i.code })),
      }) as CheckoutRequest,
  );

  const { data, status, execute, clear } = useAsyncData(
    'checkout',
    () => $ecommerce.fetch<OrderResponse[]>('checkout', {
      method: 'POST',
      body: request.value,
    }).catch((error) => {
      $ecommerce.handle(error);
      return undefined;
    }),
    {
      immediate: false,
    },
  );

  const submit = async () => {
    if (paymentMethod.value == 'Financing' && financing.value?.id.length) {
      /**
       * No need to await this call. See $credova.onCheckoutComplete
       */
      $credova.checkout(financing.value.id);
      return;
    }

    await execute();

    navigateTo('/checkout/success');
  };

  const isPaymentValid = computed(() => {
    switch (paymentMethod.value) {
      case 'CreditCard':
        return !!creditCard.value?.isValid;
      case 'Financing':
        return !!financing.value?.id;
      default:
        return false;
    }
  });

  const steps = computed<Step[]>(() => {
    const stack: Step[] = [
      {
        name: 'BillingAddress',
        title: 'Billing Address',
        caption: computed(() =>
          billingAddress.value ? address(billingAddress.value) : undefined,
        ),
        component: LazyCheckoutStepBillingAddress,
        done: computed(() => !!billingAddress.value),
      },
      {
        name: 'ShipDestination',
        title: 'Destination',
        caption: computed(() =>
          isPickup.value ? `Pick Up @ ${pickupLocation.value.name}` : 'Delivery',
        ),
        component: LazyCheckoutStepShipDestination,
        done: computed(() => isAfter('ShipDestination')),
      },
    ];

    if (quoteStore.data?.requiresShippingAddress) {
      stack.push({
        name: 'ShippingAddress',
        title: 'Shipping Address',
        caption: computed(() =>
          shippingAddress.value ? address(shippingAddress.value) : undefined,
        ),
        component: LazyCheckoutStepShippingAddress,
        done: computed(() => !!shippingAddress.value),
      });
    }

    // if (isPickup.value) {
    //   stack.push({
    //     name: 'PickupLocation',
    //     title: 'Pickup Location',
    //     caption: 'Select location for in-store pickup',
    //     component: LazyCheckoutStepPickupLocation,
    //     done: computed(() => !!pickupLocation.value),
    //   });
    // }

    if (quoteStore.data?.requiresFflDealer) {
      stack.push({
        name: 'FflDealer',
        title: 'FFL Dealer',
        caption: computed(() => fflDealer.value?.companyName),
        component: LazyCheckoutStepFflDealer,
        done: computed(() => !!fflDealer.value),
      });
    }

    stack.push({
      name: 'PaymentOptions',
      title: 'Payment Options',
      caption: computed(() => {
        if (!isPaymentValid) {
          return undefined;
        }

        if (paymentMethod.value == 'CreditCard') {
          if (!creditCard.value?.isValid) {
            return 'Credit Card';
          }

          const { masked } = useCreditCard(creditCard.value);
          return masked.value;
        }

        return paymentMethod.value ?? undefined;
      }),
      component: LazyCheckoutStepPaymentOptions,
      done: isPaymentValid,
    });

    stack.push({
      name: 'Submit',
      title: 'Submit',
      component: LazyCheckoutStepSubmit,
      done: false,
    });

    return stack;
  });

  const stepNames = computed(() => steps.value.map(s => s.name));

  const step = ref(stepNames.value[0]!);

  const index = computed(() => stepNames.value.indexOf(step.value));

  const goTo = (destination: CheckoutStep) =>
    (step.value = stepNames.value.includes(destination)
      ? destination
      : step.value);

  const goToNext = () => {
    if (isLast.value) {
      return;
    }

    step.value = stepNames.value[index.value + 1]!;
  };

  const goToPrevious = () => {
    if (isFirst.value) {
      return;
    }

    step.value = stepNames.value[index.value - 1]!;
  };

  const isAfter = (step: CheckoutStep) =>
    index.value > stepNames.value.indexOf(step);

  const isFirst = computed(() => index.value === 0);

  const isLast = computed(() => index.value === stepNames.value.length - 1);

  const reset = () => {
    drawer.value = false;
    billingAddress.value = null;
    shippingAddress.value = null;
    // pickupLocation.value = null;
    fflDealer.value = null;
    isPickup.value = false;
    isInsured.value = true;
    isLayaway.value = false;
    customerNotes.value = null;
    paymentMethod.value = null;
    storeCredits.value = [];
    creditCard.value = null;
    financing.value = null;
    step.value = stepNames.value[0]!;
    clear();
  };

  watch(
    () => quoteStore.data,
    () => {
      financing.value = quoteStore.data?.financing ?? null;
    },
  );

  watch(status, () =>
    status.value == 'pending' ? Loading.show() : Loading.hide(),
  );

  // Set insurance flag to true by default if it's not a pickup
  // and false if it is.
  watch(isPickup, value => isInsured.value = !value);

  whenever(data, orders => $bus.emit('checkout:complete', orders));

  $credova.onCheckoutComplete(() => execute());

  return {
    steps: computed(() => steps.value),
    step,
    goTo,
    goToNext,
    goToPrevious,
    isFirst,
    isLast,
    isAfter,
    isPaymentValid,
    drawer,
    billingAddress,
    shippingAddress,
    pickupLocation,
    fflDealer,
    paymentMethod,
    isPickup,
    isLayaway,
    isInsured,
    customerNotes,
    creditCard,
    financing,
    storeCredits,
    data,
    submit,
    status,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCheckoutStore, import.meta.hot));
}
