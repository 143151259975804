<template>
  <section>
    <div class="row q-col-gutter-md q-mb-md">
      <div class="col-12 col-sm-6">
        <q-input
          v-model="search"
          :loading="status == 'pending'"
          label="Search"
          maxlength="50"
          debounce="350"
          clearable
          dense
        />
      </div>
      <div class="col-12 col-sm-6">
        <q-select
          v-model="state"
          :options="dropdown.states"
          :disable="status == 'pending'"
          label="State"
          hint="Filter dealers by state."
          map-options
          emit-value
          clearable
          dense
        />
      </div>
    </div>

    <q-table
      v-model:pagination="pagination"
      :selected="checkoutStore.fflDealer ? [checkoutStore.fflDealer] : []"
      :rows="items"
      :columns="columns"
      :loading="status == 'pending'"
      :grid="$q.screen.lt.md"
      :rows-per-page-options="[10, 25, 50]"
      color="primary"
      row-key="id"
      selection="single"
      square
      @request="request"
      @row-click="onRowClick"
      @update:selected="checkoutStore.fflDealer = $event[0] ?? null"
    >
      <template #body-selection="scope">
        <q-checkbox
          v-model="scope.selected"
          :disable="quoteStore.pending"
        />
      </template>
    </q-table>

    <div
      v-if="checkoutStore.fflDealer"
      class="text-body2 q-pt-md"
    >
      To ensure a smooth and hassle-free transfer process, we recommend reaching
      out to {{ checkoutStore.fflDealer.companyName }} before completing the order
      to confirm they will accept your transfer(s).
    </div>

    <div
      v-else
      class="text-body2 q-pt-md"
    >
      Can't find your dealer? That's okay, we can figure it out later! You can
      skip this step, but keep in mind fulfillment will be delayed until we have
      a valid dealer on file.
    </div>

    <q-stepper-navigation>
      <q-btn
        :disable="!canContinue"
        :loading="quoteStore.pending"
        :label="checkoutStore.fflDealer ? 'Continue' : 'Skip'"
        color="primary"
        square
        @click="checkoutStore.goToNext()"
      />
    </q-stepper-navigation>
  </section>
</template>

<script lang="ts" setup>
import type { QTableProps } from 'quasar';
import { whenever } from '@vueuse/core';
import type { FflDealerResponse, PaginatedCollection } from '~/types/ecommerce';
import type { Pagination, TableRequestParams } from '~/types/quasar';

const columns = [
  {
    name: 'name',
    required: true,
    label: 'Name',
    field: 'companyName',
    sortable: true,
    align: 'left',
  },
  {
    name: 'address',
    required: true,
    label: 'Address',
    field: (row: FflDealerResponse) =>
      concat(', ', row.shippingAddress.street, row.shippingAddress.street2),
    sortable: true,
    align: 'left',
  },
  {
    name: 'city',
    required: true,
    label: 'City',
    field: (row: FflDealerResponse) => row.shippingAddress.city,
    sortable: true,
    align: 'left',
  },
  {
    name: 'state',
    required: true,
    label: 'State',
    field: (row: FflDealerResponse) => row.shippingAddress.state,
    sortable: true,
    align: 'left',
  },
  {
    name: 'postcode',
    required: true,
    label: 'Postal Code',
    field: (row: FflDealerResponse) => row.shippingAddress.postcode,
    sortable: true,
    align: 'left',
  },
  {
    name: 'phone',
    required: true,
    label: 'Phone',
    field: 'phoneNumber',
    format: (value?: string | null) => (value ? phone(value) : ''),
    sortable: false,
    align: 'right',
  },
] as QTableProps['columns'];

const checkoutStore = useCheckoutStore();
const quoteStore = useQuoteStore();
const { concat, phone } = useFormatting();
const { $ecommerce } = useNuxtApp();

const search = ref<string | null>(null);

const state = ref<string | null>(
  checkoutStore.shippingAddress?.state
  ?? checkoutStore.billingAddress?.state
  ?? null,
);

const pagination = ref<Pagination>({
  sortBy: null,
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
});

const query = computed(() => ({
  page: pagination.value.page,
  pageSize: pagination.value.rowsPerPage,
  sorts: sorts.value,
  filters: filters.value,
}));

const sorts = computed(() => {
  switch (pagination.value.sortBy) {
    case 'name':
      return `${pagination.value.descending ? '-' : ''}CompanyName`;
    case 'address':
      return `${pagination.value.descending ? '-' : ''}ShippingStreet`;
    case 'city':
      return `${pagination.value.descending ? '-' : ''}ShippingCity`;
    case 'postcode':
      return `${pagination.value.descending ? '-' : ''}ShippingPostcode`;
    case 'state':
      return `${pagination.value.descending ? '-' : ''}ShippingState`;
    default:
      return undefined;
  }
});

const filters = computed(() => {
  const stack: string[] = [];

  if (search.value?.length) {
    stack.push(
      `(CompanyName|ShippingStreet|ShippingCity)@=${search.value}`,
    );
  }

  if (state.value?.length) {
    stack.push(`ShippingState==${state.value}`);
  }

  return stack.join(',');
});

const { data, status } = await useAsyncData(
  'dealers',
  () => $ecommerce.fetch<PaginatedCollection<FflDealerResponse>>('dealers', {
    params: query.value,
  }).catch((error) => {
    const _ = error.status == 404 || $ecommerce.handle(error);
    return undefined;
  }),
  {
    lazy: true,
    watch: [query],
  },
);

const request = (props: TableRequestParams) => {
  pagination.value.page = props.pagination.page;
  pagination.value.rowsPerPage = props.pagination.rowsPerPage;
  pagination.value.sortBy = props.pagination.sortBy;
  pagination.value.descending = props.pagination.descending;
};

const items = computed(() => data.value?.items ?? []);

const canContinue = computed(() => !quoteStore.error);

const onRowClick = (_evt: Event, row: FflDealerResponse, _index: number) =>
  checkoutStore.fflDealer = row;

whenever(
  () => data.value?.totalItems,
  total => (pagination.value.rowsNumber = total),
);
</script>

<style lang="scss" scoped>
:deep(.q-table__grid-item--selected) {
  transform: none;
}
</style>
