<template>
  <section>
    <div
      v-if="status == 'pending'"
      class="row"
    >
      <q-item
        v-for="item in Array(6)"
        :key="item"
        class="col-12 col-sm-6"
      >
        <q-item-section
          class="flex-center"
          avatar
        >
          <q-skeleton
            type="circle"
            size="20px"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label>
            <q-skeleton type="text" />
          </q-item-label>
          <q-item-label>
            <q-skeleton type="text" />
          </q-item-label>
          <q-item-label>
            <q-skeleton type="text" />
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-skeleton
            type="QBtn"
            width="60px"
            square
          />
        </q-item-section>
      </q-item>
    </div>

    <div
      v-else
      class="row"
    >
      <q-item
        v-for="item in items"
        :key="item.id"
        :focused="checkoutStore.billingAddress?.id == item.id"
        class="col-12 col-sm-6"
        tag="label"
        clickable
      >
        <q-item-section
          :avatar="$q.screen.gt.md"
          side
        >
          <q-radio
            :model-value="checkoutStore.billingAddress?.id"
            :val="item.id"
            :dense="$q.screen.lt.lg"
            class="col-12 col-sm-6"
            @update:model-value="checkoutStore.billingAddress = item"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="row q-gutter-x-xs">
            <span class="text-weight-bold">
              {{ join(item.firstName, item.lastName) }}
            </span>
            <span v-if="item.company">{{ item.company }}</span>
          </q-item-label>
          <q-item-label>
            {{
              join(
                concat(', ', item.street, item.street2, item.city, item.state),
                item.postcode,
              )
            }}
          </q-item-label>
          <q-item-label>
            {{ phone(item.phoneNumber) }}
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            label="Edit"
            square
            @click.stop="onEdit(item)"
          />
        </q-item-section>
      </q-item>

      <q-item
        v-if="items.length < 10"
        class="col-12 col-sm-6"
        clickable
        @click="onAdd()"
      >
        <q-item-section avatar>
          <q-avatar
            :icon="mdiPlus"
            size="40px"
            font-size="28px"
          />
        </q-item-section>
        <q-item-section clickable>
          Add New Address
        </q-item-section>
      </q-item>
    </div>

    <q-stepper-navigation>
      <q-btn
        :disable="!canContinue"
        :loading="quoteStore.pending"
        color="primary"
        label="Continue"
        square
        @click="checkoutStore.goToNext()"
      />
    </q-stepper-navigation>
  </section>
</template>

<script lang="ts" setup>
import { mdiPlus } from '@quasar/extras/mdi-v7';
import { LazyDialogAccountAddress } from '#components';
import type { AddressResponse, PaginatedCollection } from '~/types/ecommerce';

const checkoutStore = useCheckoutStore();
const quoteStore = useQuoteStore();
const $q = useQuasar();
const { $ecommerce } = useNuxtApp();
const { join, concat, phone } = useFormatting();

const { data, status, refresh } = await useAsyncData(
  'addresses',
  () => $ecommerce.fetch<PaginatedCollection<AddressResponse>>('addresses', {
    params: {
      pageSize: 10,
    },
  }).catch((error) => {
    const _ = error.status == 404 || $ecommerce.handle(error);
    return undefined;
  }),
  {
    lazy: true,
  },
);

const items = computed(() => data.value?.items ?? []);

const onAdd = () =>
  $q
    .dialog({
      component: LazyDialogAccountAddress,
    })
    .onOk(() => refresh());

const onEdit = (entity: AddressResponse) =>
  $q
    .dialog({
      component: LazyDialogAccountAddress,
      componentProps: {
        entity,
      },
    })
    .onOk((entity: AddressResponse) => {
      if (checkoutStore.billingAddress?.id == entity.id) {
        checkoutStore.billingAddress = entity;
      }

      refresh();
    });

const canContinue = computed(
  () => !quoteStore.error && !!checkoutStore.billingAddress,
);
</script>
