import type { Field } from 'typesense-utils';
import type { Product } from '~/types/search';
import type { OperationMode } from '~/types/typesense';

export const PRODUCT_INCLUDE_FIELDS: Field<Product>[] = [
  'averageScore',
  'id',
  'isAmmunition',
  'isClass3',
  'isFirearm',
  'isFreeShipping',
  'isHazmat',
  'isInStock',
  'isInStore',
  'isOnSale',
  'isPartnerFulfilled',
  'isQualifiedProfessional',
  'isVariable',
  'mpn',
  'name',
  'price',
  'regularPrice',
  'reviewCount',
  'slug',
  'style',
  'thumbnailUrl',
  'upc',
  'orderType',
  'mapPolicy',
  'mapPrice',
  'hasRebates',
  'warehouses',
];

export const PRODUCT_SORTS = [
  {
    label: 'Relevance',
    key: 'relevance',
    value: '',
  },
  {
    label: 'Price: Low to High',
    key: 'price_asc',
    value: 'price:asc',
  },
  {
    label: 'Price: High to Low',
    key: 'price_desc',
    value: 'price:desc',
  },
  {
    label: 'Avg. Customer Review',
    key: 'rating',
    value: 'averageScore:desc,reviewCount:desc,popularity:desc',
  },
  {
    label: 'Best Sellers',
    key: 'bestselling',
    value: 'quantitySold:desc',
  },
];

export const PRODUCT_QUERY_BY: Field<Product>[] = ['name', 'sku', 'mpn', 'style', 'upc', 'keywords'];

export const PRODUCT_INFIX: OperationMode[] = ['off', 'off', 'always', 'fallback', 'fallback', 'off'];
