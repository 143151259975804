
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutIYttGxXltYMeta } from "/workspace/pages/about.vue?macro=true";
import { default as accessoriesYklcOBn2jUMeta } from "/workspace/pages/accessories.vue?macro=true";
import { default as addressesfGcOzScEheMeta } from "/workspace/pages/account/addresses.vue?macro=true";
import { default as documentswYAXP5JYPgMeta } from "/workspace/pages/account/documents.vue?macro=true";
import { default as indexeEKJArLYGsMeta } from "/workspace/pages/account/index.vue?macro=true";
import { default as notifications27l6EVAXL9Meta } from "/workspace/pages/account/notifications.vue?macro=true";
import { default as _91id_93eg3nfu0NfiMeta } from "/workspace/pages/account/orders/[id].vue?macro=true";
import { default as indexbEBWkKJ0aBMeta } from "/workspace/pages/account/orders/index.vue?macro=true";
import { default as _91slug_93L0SQjcwZP9Meta } from "/workspace/pages/blog/[slug].vue?macro=true";
import { default as indexRgrKfU8NaHMeta } from "/workspace/pages/blog/index.vue?macro=true";
import { default as bow_45tech7o1mAiCCuGMeta } from "/workspace/pages/bow-tech.vue?macro=true";
import { default as _91slug_938zDbi92bpEMeta } from "/workspace/pages/brand/[slug].vue?macro=true";
import { default as brands5pbUCY9d4WMeta } from "/workspace/pages/brands.vue?macro=true";
import { default as _91_46_46_46slug_935KTAHVuucpMeta } from "/workspace/pages/category/[...slug].vue?macro=true";
import { default as shootingFta6KVGFMRMeta } from "/workspace/pages/category/shooting.vue?macro=true";
import { default as index5WsByiI7CIMeta } from "/workspace/pages/checkout/index.vue?macro=true";
import { default as successgZvP6TX3zyMeta } from "/workspace/pages/checkout/success.vue?macro=true";
import { default as contact8zxLAXYQX4Meta } from "/workspace/pages/contact.vue?macro=true";
import { default as dealerszHJ7jLpaaCMeta } from "/workspace/pages/dealers.vue?macro=true";
import { default as _91slug_93iRcanRPaIiMeta } from "/workspace/pages/group/[slug].vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93hWDsquQaUzMeta } from "/workspace/pages/page/[...slug].vue?macro=true";
import { default as _91slug_93viGG2BccMVMeta } from "/workspace/pages/product/[slug].vue?macro=true";
import { default as reviewsiY9Eh22YuxMeta } from "/workspace/pages/reviews.vue?macro=true";
import { default as searchQVlILEhc6OMeta } from "/workspace/pages/search.vue?macro=true";
import { default as _91slug_9365u4AwyFITMeta } from "/workspace/pages/videos/[slug].vue?macro=true";
import { default as indexMjtR1lZdv5Meta } from "/workspace/pages/videos/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/workspace/pages/about.vue")
  },
  {
    name: "accessories",
    path: "/accessories",
    component: () => import("/workspace/pages/accessories.vue")
  },
  {
    name: "account-addresses",
    path: "/account/addresses",
    meta: addressesfGcOzScEheMeta || {},
    component: () => import("/workspace/pages/account/addresses.vue")
  },
  {
    name: "account-documents",
    path: "/account/documents",
    meta: documentswYAXP5JYPgMeta || {},
    component: () => import("/workspace/pages/account/documents.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexeEKJArLYGsMeta || {},
    component: () => import("/workspace/pages/account/index.vue")
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    meta: notifications27l6EVAXL9Meta || {},
    component: () => import("/workspace/pages/account/notifications.vue")
  },
  {
    name: "account-orders-id",
    path: "/account/orders/:id()",
    component: () => import("/workspace/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders",
    path: "/account/orders",
    meta: indexbEBWkKJ0aBMeta || {},
    component: () => import("/workspace/pages/account/orders/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/workspace/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/workspace/pages/blog/index.vue")
  },
  {
    name: "bow-tech",
    path: "/bow-tech",
    component: () => import("/workspace/pages/bow-tech.vue")
  },
  {
    name: "brand-slug",
    path: "/brand/:slug()",
    meta: _91slug_938zDbi92bpEMeta || {},
    alias: ["/manufacturer/:slug","/b/:slug"],
    component: () => import("/workspace/pages/brand/[slug].vue")
  },
  {
    name: "brands",
    path: "/brands",
    component: () => import("/workspace/pages/brands.vue")
  },
  {
    name: "category-slug",
    path: "/category/:slug(.*)*",
    meta: _91_46_46_46slug_935KTAHVuucpMeta || {},
    alias: ["/c/:slug(.*)*"],
    component: () => import("/workspace/pages/category/[...slug].vue")
  },
  {
    name: "category-shooting",
    path: "/category/shooting",
    meta: shootingFta6KVGFMRMeta || {},
    alias: ["/c/shooting"],
    component: () => import("/workspace/pages/category/shooting.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: index5WsByiI7CIMeta || {},
    component: () => import("/workspace/pages/checkout/index.vue")
  },
  {
    name: "checkout-success",
    path: "/checkout/success",
    meta: successgZvP6TX3zyMeta || {},
    component: () => import("/workspace/pages/checkout/success.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/workspace/pages/contact.vue")
  },
  {
    name: "dealers",
    path: "/dealers",
    component: () => import("/workspace/pages/dealers.vue")
  },
  {
    name: "group-slug",
    path: "/group/:slug()",
    component: () => import("/workspace/pages/group/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: "page-slug",
    path: "/page/:slug(.*)*",
    meta: _91_46_46_46slug_93hWDsquQaUzMeta || {},
    component: () => import("/workspace/pages/page/[...slug].vue")
  },
  {
    name: "product-slug",
    path: "/product/:slug()",
    meta: _91slug_93viGG2BccMVMeta || {},
    alias: ["/p/:slug"],
    component: () => import("/workspace/pages/product/[slug].vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/workspace/pages/reviews.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/workspace/pages/search.vue")
  },
  {
    name: "videos-slug",
    path: "/video/:slug",
    meta: _91slug_9365u4AwyFITMeta || {},
    alias: ["/v/:slug","/videos/:slug"],
    component: () => import("/workspace/pages/videos/[slug].vue")
  },
  {
    name: "videos",
    path: "/videos",
    component: () => import("/workspace/pages/videos/index.vue")
  }
]